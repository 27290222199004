import React from 'react';
import {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image';
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

const ShowcaseGallery = () => {

    const data = useStaticQuery(graphql `
    query showcaseGallery {
      sanityHomePage {
        showcaseSection {
          alt
          _key
          asset {
            gatsbyImageData
          }
        }
      }
    }    
  `);

    const options = {
        settings: {
            disablePanzoom: true
        },
        buttons: {
            backgroundColor: 'rgba(30,30,36,0.8)',
            iconColor: 'rgba(255, 255, 255, 0.8)',
            iconPadding: '10px',
            showAutoplayButton: false,
            showCloseButton: true,
            showDownloadButton: false,
            showFullscreenButton: true,
            showNextButton: true,
            showPrevButton: true,
            showThumbnailsButton: true,
            size: '40px'
        }
    };

    const imgData = data.sanityHomePage.showcaseSection;

    const showcaseImages = imgData.map(image => (<GatsbyImage
        key={image._key}
        image={image.asset
        ?.gatsbyImageData}
        alt={image.alt
        ? image.alt
        : ""}
        className="mx-3 my-3"/>));

    return (
        <section id="gallery" className="py-3 px-3">
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 cursor-pointer">
                        {showcaseImages}
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </section>
    );
};

export default ShowcaseGallery;