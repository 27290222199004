import React, {useState, useEffect} from "react"
import styled from "styled-components"
import Logo from "./logo"
import {Link as LinkScroll} from "react-scroll";
import {Link} from "gatsby";
import {Spin as Hamburger} from 'hamburger-react'

const Navigation = styled.nav `
  height: 10rem;
  width: 100%;
  display: flex;
  background-color: transparent;
  color: #fff;
  position: absolute;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 999;
  align-self: center;
  transition: background-color 500ms;
}
&.active{
  position: fixed;
  background-color: #fff;
  color: #000;
}

  @media (max-width: 768px) {
    position: fixed;
    background-color: transparent;
    left: 0;
    right: 0;
    left: 0;
    color: #000;
  }
`;

const Toggle = styled.div `
  display: none;
  height: 45px;
  cursor: pointer;
  padding: 0 0.8rem;
  z-index: 998;
  align-self: center;
  color: #fff;

  &.open,
  &.active {
    color: #000;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Navbox = styled.div `
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: center;
    background-color: #fff;
    transition: all 0.3s ease-in;
    left: ${props => (props.open
    ? "-100%"
    : "0")};
  }
`;

const NavItem = styled(LinkScroll)`
  text-decoration: none;
  font-size: 2rem;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  position: relative;
  cursor: pointer;

}
&.active{
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    content: ".";
    color: transparent;
    background: #000;
    height: 1px;
    transition: all 0.4s ease-in;
  }
}

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #000;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  @media (max-width: 768px) {
    padding: 5px 0;
    z-index: 6;

    :after {
        display: none;
      }
  }
`;

const LogoWrap = styled.div `
  align-self: center;
  flex: 0 1 100px;
  z-index: 999;

  img {
    filter: invert(1);
  }

  &.active,
  &.open {
    img {
      filter: invert(0);
    } 
  }
`;

const Navbar = () => {
    const [navbarOpen,
        setNavbarOpen] = useState(false);
    const [navbar,
        setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 1) {
            setNavbar(true)
        } else {
            setNavbar(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => window.removeEventListener('scroll', changeBackground);
    });

    useEffect(() => {
        if (navbarOpen) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [navbarOpen]);

    return (
        <Navigation
            className={navbar
            ? 'navbar active shadow-lg'
            : 'navbar'}>
            <LogoWrap
                as={Link}
                to="/"
                className={`${navbar
                ? 'active'
                : ''} ${navbarOpen
                    ? 'open'
                    : ''}`}>
                <Logo/>
            </LogoWrap>
            <Toggle
                navbarOpen={navbarOpen}
                onClick={() => setNavbarOpen(!navbarOpen)}
                className={`${navbar
                ? 'active'
                : ''} ${navbarOpen
                    ? 'open'
                    : ''}`}>
                <Hamburger direction="right" toggled={navbarOpen} toggle={setNavbarOpen}/>
            </Toggle>
            {navbarOpen
                ? (
                    <Navbox onClick={() => setNavbarOpen(!navbarOpen)}>
                        <NavItem
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            to="portfolio"
                            title="Portfolio"
                            smooth
                            duration={1000}
                            offset={-100}>Portfolio
                        </NavItem>
                        <NavItem
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            to="services"
                            title="Services"
                            smooth
                            duration={1000}
                            offset={-100}>Services
                        </NavItem>
                        <NavItem
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            to="about"
                            title="About"
                            smooth
                            duration={1000}
                            offset={-100}>About</NavItem>
                        <NavItem
                            to="clients"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            title="Clients"
                            smooth
                            duration={1000}
                            offset={-100}>Clients</NavItem>
                        <NavItem
                            to="contact"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            title="Contact"
                            smooth
                            duration={1000}
                            offset={-100}>Contact</NavItem>
                        <div className="social-links justify-center">
                            <a
                                href="https://www.instagram.com/anatoletuzlak/"
                                target="_blank"
                                rel="noreferrer noopener">
                                <i className="fa fa-instagram text-black" aria-hidden="true"/>
                            </a>
                        </div>
                    </Navbox>
                )
                : (
                    <Navbox open>
                        <NavItem
                            to="portfolio"
                            title="Portfolio"
                            smooth
                            duration={1000}
                            offset={-100}
                            activeClass='active'
                            spy={true}>Portfolio
                        </NavItem>
                        <NavItem
                            to="services"
                            title="Services"
                            smooth
                            duration={1000}
                            offset={-100}
                            activeClass='active'
                            spy={true}>Services</NavItem>
                        <NavItem
                            to="about"
                            title="About"
                            smooth
                            duration={1000}
                            offset={-100}
                            activeClass='active'
                            spy={true}>About</NavItem>
                        <NavItem
                            to="clients"
                            title="Clients"
                            smooth
                            duration={1000}
                            offset={-100}
                            activeClass='active'
                            spy={true}>Clients</NavItem>
                        <NavItem
                            to="contact"
                            title="Contact"
                            smooth
                            duration={1000}
                            offset={-100}
                            activeClass='active'
                            spy={true}>Contact</NavItem>
                        <div className="social-links justify-center">
                            <a
                                href="https://www.instagram.com/anatoletuzlak/"
                                target="_blank"
                                rel="noreferrer noopener">
                                <i
                                    className={navbar
                                    ? 'fa fa-instagram text-black'
                                    : 'fa fa-instagram text-white'}
                                    aria-hidden="true"/>
                            </a>
                        </div>
                    </Navbox>
                )}
        </Navigation>
    );
};

export default Navbar;
