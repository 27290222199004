import React from "react";
import FormImage from "./FormImage";
import Form from "./form";

const ContactFormMobile = () => {

    return (
            <div className="grid grid-cols-1 items-center justify-center h-3/4 w-full bg-gradient-to-t from-primary to-secondary">
                <FormImage/>
                <Form/>
            </div>
    );
};

export default ContactFormMobile;
