import React from "react";
import {useStaticQuery, graphql} from "gatsby";

const RightOverlayContent = ({isAnimated, setIsAnimated}) => {
    const data = useStaticQuery(graphql `
       query {
        sanityHomePage {
          contactForm {
            contactHeader
            contactText
          }
        }
      }
  `);

    const heading = data.sanityHomePage.contactForm.contactHeader;
    const content = data.sanityHomePage.contactForm.contactText;

    return (
        <div className="p-8 text-center">
            <h1 className="text-6xl font-bold text-white mb-4">
                {heading}
            </h1>
            <h2 className="text-xl text-white">{content}</h2>
            <div className="mt-16">
                <button
                    type="button"
                    onClick={(e) => {
                    setIsAnimated(!isAnimated);
                }}
                    className="hero-cta cta-btn cta-btn--hero-w">
                    Contact Me
                </button>
            </div>
        </div>
    );
};

export default RightOverlayContent;
