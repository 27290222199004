import React from "react";
import ContactForm from "../contact-form/ContactForm";
import ContactFormMobile from "../contact-form/ContactFormMobile";
import MediaQuery from 'react-responsive';

const Contact = () => {

    return (
        <section id="contact">
            <MediaQuery minWidth={769}>
                <ContactForm/>
            </MediaQuery>
            <MediaQuery maxWidth={768}>
                <ContactFormMobile/>
            </MediaQuery>
        </section>
    );
};

export default Contact;