import React from 'react';
import {useStaticQuery, graphql} from "gatsby";
import Header from './header';
import {Link} from 'react-scroll';
import {motion} from 'framer-motion';
import {GatsbyImage} from 'gatsby-plugin-image';

import '../style/main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const whiteBox = {
    initial: {
        height: "100vh",
        top: 0,
        zIndex: 9999
    },
    animate: {
        height: 0,
        transition: {
            when: "afterChildren",
            delay: 1.5,
            duration: 1.5,
            ease: [0.87, 0, 0.13, 1]
        }
    }
};

const logoContainer = {
    initial: {
        opacity: 1
    },
    animate: {
        opacity: 0,
        transition: {
            delay: 1,
            duration: 1,
            when: "afterChildren"
        }
    }
};

const Layout = ({children, siteTitle}) => {

    const data = useStaticQuery(graphql `
    query {
      sanitySiteSettings {
        logo {
          asset {
            gatsbyImageData(height: 100, placeholder: NONE)
          }
        }
      }
    }
  `);

    const logo = data.sanitySiteSettings.logo.asset.gatsbyImageData

    return (
        <>
            <Header siteTitle={siteTitle}/>
            <div id="top"/>
            <motion.div
                className="fixed flex items-center justify-center w-full bg-white"
                initial="initial"
                animate="animate"
                variants={whiteBox}
                onAnimationStart={() => document.body.classList.add("overflow-hidden")}
                onAnimationComplete={() => document.body.classList.remove("overflow-hidden")}>
                <motion.div initial="initial" animate="animate" variants={logoContainer}>
                    <GatsbyImage image={logo} alt="Logo"/>
                </motion.div>
            </motion.div>
            <main>
                {children}
            </main>
            <footer className="footer">
                <span className="back-to-top">
                    <Link to="top" smooth duration={1000}>
                        <i className="fa fa-angle-up fa-3x" aria-hidden="true"/>
                    </Link>
                </span>
                <div className="social-links justify-center">
                    <a
                        aria-label="Instagram"
                        href="https://www.instagram.com/anatoletuzlak/"
                        target="_blank"
                        rel="noreferrer noopener">
                        <i className="fa fa-instagram" aria-hidden="true"/>
                    </a>
                    <a
                        aria-label="LinkedIn"
                        href="https://www.linkedin.com/in/anatole-tuzlak-01a16282/"
                        target="_blank"
                        rel="noreferrer noopener">
                        <i className="fa fa-linkedin" aria-hidden="true"/>
                    </a>
                </div>
                <div className="pt-4">
                    © {new Date().getFullYear()}, AT Visuals
                </div>
            </footer>
        </>
    )
};

export default Layout;
