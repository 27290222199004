import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from 'gatsby-plugin-image';

const FormImage = () => {
  const data = useStaticQuery(graphql `
  query {
    sanityHomePage {
      contactForm {
        formImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`);

const aboutImg = data.sanityHomePage.contactForm.formImage.asset.gatsbyImageData

  return (
    <GatsbyImage image={aboutImg} alt="Form Image" className="h-full w-full"/>
  );
};

export default FormImage;