import React, {useState} from 'react';
import {useStaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {BgImage} from 'gbimage-bridge';
import Fade from 'react-reveal/Fade';
import { Link } from 'gatsby';
import {Modal} from './modal/modal';

const Services = () => {

    const data = useStaticQuery(graphql `
    query {
        sanityHomePage {
          firstSection {
            asset {
              gatsbyImageData
            }
            header
            text
          }
          secondSection {
            asset {
              gatsbyImageData
            }
            header
            text
          }
        }
      }    
  `);

    const [showModal,
        setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(prev => !prev)
    };

    const introImg1 = data.sanityHomePage.firstSection.asset.gatsbyImageData;
    const introHeader1 = data.sanityHomePage.firstSection.header;
    const introText1 = data.sanityHomePage.firstSection.text;
    const introImg2 = data.sanityHomePage.secondSection.asset.gatsbyImageData;
    const introHeader2 = data.sanityHomePage.secondSection.header;
    const introText2 = data.sanityHomePage.secondSection.text;

    return (
        <section id="services">
            <div className="container py-12 hidden lg:block">
                {/* Section 1 */}
                <div
                    className="hidden lg:grid lg:grid-cols-2 items-center justify-items-center py-10">
                    <Fade left duration={1000} delay={500} distance="30px">
                        <div className="w-full h-500px pr-5">
                            <BgImage
                                image={introImg1}
                                alt="Intro Image"
                                style
                                ={{
                                backgroundSize: "cover",
                                width: "100%",
                                height: "100%"
                            }}/>
                        </div>
                    </Fade>
                    <Fade right duration={1000} distance="30px">
                        <div className="lg:px-8 py-8 mx-auto">
                            <div className="intro__heading">
                                <h2 className="uppercase">{introHeader1}</h2>
                            </div>
                            <p className="mb-4">{introText1}</p>
                            <Link to="/landscape" title="Portfolio" smooth duration={1000} offset={-100}>
                                <button type="button" className="cta-btn cta-btn--hero">Portfolio</button>
                            </Link>
                        </div>
                    </Fade>
                </div>
                {/* Section 2 */}
                <div
                    className="hidden lg:grid lg:grid-cols-2 items-center justify-items-center py-10">
                    <Fade left duration={1000} distance="30px">
                        <div className="lg:px-8 py-8 mx-auto">
                            <div className="intro__heading">
                                <h2 className="uppercase">{introHeader2}</h2>
                            </div>
                            <p className="mb-4">{introText2}</p>
                            <button type="button" onClick={openModal} className="cta-btn cta-btn--hero">
                                Demo Reel
                            </button>
                        </div>
                    </Fade>
                    <Fade right duration={1000} delay={500} distance="30px">
                        <div className="w-full h-500px pl-5">
                            <BgImage
                                image={introImg2}
                                alt="Intro Image"
                                style
                                ={{
                                backgroundSize: "cover",
                                width: "100%",
                                height: "100%"
                            }}/>
                        </div>
                    </Fade>
                </div>
            </div>
            {/* Mobile view */}
            <div className="flex flex-col lg:hidden justify-center w-full h-full p-5">
                <Fade bottom duration={1000} distance="30px">
                    <div className="w-full my-4">
                        <GatsbyImage image={introImg1} alt="Intro Image"/>
                    </div>
                </Fade>
                <Fade bottom duration={1000} distance="30px">
                    <div className="w-full p-8 bg-gradient-to-r from-primary to-secondary z-50">
                        <div className="flex flex-col text-white items-center">
                            <h2 className="uppercase">{introHeader1}</h2>
                            <p className="text-white my-5">
                                {introText1}
                            </p>
                            <Link to="/landscape" title="Portfolio" smooth duration={1000} offset={-100}>
                                <button type="button" className="cta-btn cta-btn--hero">Portfolio</button>
                            </Link>
                        </div>
                    </div>
                </Fade>
                <div className="pt-5">
                    <Fade bottom duration={1000} distance="30px">
                        <div className="w-full my-4">
                            <GatsbyImage image={introImg2} alt="Intro Image"/>
                        </div>
                    </Fade>
                    <Fade bottom duration={1000} distance="30px">
                        <div className="w-full p-8 bg-gradient-to-r from-primary to-secondary z-50">
                            <div className="flex flex-col text-white items-center">
                                <h2 className="uppercase">{introHeader2}</h2>
                                <p className="text-white my-5">
                                    {introText2}
                                </p>
                                <button type="button" onClick={openModal} className="cta-btn cta-btn--hero">
                                    Demo Reel
                                </button>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
            <Modal showModal={showModal} setShowModal={setShowModal}/>
        </section>

    );
};

export default Services;