import React from "react";
import {useStaticQuery, graphql} from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image';

const ContactImage = () => {
  const data = useStaticQuery(graphql `
  query {
    sanityHomePage {
      contactForm {
        contactImage {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`);

const aboutImg = data.sanityHomePage.contactForm.contactImage.asset.gatsbyImageData;

  return (
    <GatsbyImage image={aboutImg} alt="Contact Image" className="h-full w-full"/>
  );
};

export default ContactImage;
