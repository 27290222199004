import React from "react";
import Form from "./form";

const LeftOverlayContent = ({ isAnimated, setIsAnimated }) => {
  return (
      <Form/>
  );
};

export default LeftOverlayContent;
