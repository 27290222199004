import React from 'react';
import { graphql } from 'gatsby';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';
import Landing from '../components/landings';
import Portfolio from '../components/portfolio';
import Services from '../components/services';
import About from '../components/about';
import Clients from '../components/clients';
import ShowcaseGallery from '../components/gallery/showcaseGallery';
import Contact from '../components/contact';

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  };

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  };

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
     <Landing/>
     <Portfolio/>
     <Services/>
     <About/>
     <Clients/>
     <ShowcaseGallery/>
     <Contact/>
    </Layout>
  );
};

export default IndexPage;
