import React from 'react';
import {useStaticQuery, graphql, Link} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Fade from 'react-reveal/Fade';

const Portfolio = () => {

    const data = useStaticQuery(graphql `
    query {
        sanityHomePage {
          portfolioTiles {
            image1 {
              asset {
                gatsbyImageData
              }
            }
            image2 {
              asset {
                gatsbyImageData
              }
            }
            image3 {
              asset {
                gatsbyImageData
              }
            }
            image4 {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
  `);

  const pData = data.sanityHomePage.portfolioTiles;
  
  const tileImg1 = pData.image1.asset.gatsbyImageData;
  const tileImg2 = pData.image2.asset.gatsbyImageData;
  const tileImg3 = pData.image3.asset.gatsbyImageData;
  const tileImg4 = pData.image4.asset.gatsbyImageData;

    return (
        <section id="portfolio" className="px-5">
                <div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-5 lg:gap-10 lg:px-5">
                    <div className="lg:mb-5">
                        <Fade bottom duration={1000} distance="30px">
                            <Link to="/landscape">
                                <div className="hover-lift shadow-lg">
                                    <div className="portfolio-overlay justify-center items-center">
                                        <h1>Landscape</h1>
                                    </div>
                                    <GatsbyImage image={tileImg1} alt="Landscape Photo"/>
                                </div>
                            </Link>
                        </Fade>
                    </div>

                    <div className="lg:mb-5">
                        <Fade bottom duration={1000} distance="30px">
                            <Link to="/action">
                                <div className="hover-lift shadow-lg">
                                    <div className="portfolio-overlay justify-center items-center">
                                        <h1>Action</h1>
                                    </div>
                                    <GatsbyImage image={tileImg2} alt="Lifestyle Photo"/>
                                </div>
                            </Link>
                        </Fade>
                    </div>

                    <div className="lg:mb-5">
                        <Fade bottom duration={1000} distance="30px">
                            <Link to="/lifestyle">
                                <div className="hover-lift shadow-lg">
                                    <div className="portfolio-overlay justify-center items-center">
                                        <h1>Lifestyle</h1>
                                    </div>
                                    <GatsbyImage image={tileImg3} alt="Intro Photo"/>
                                </div>
                            </Link>
                        </Fade>
                    </div>

                    <Fade bottom duration={1000} distance="30px">
                    <div className="lg:mb-5">
                            <Link to="/wildlife">
                                <div className="hover-lift shadow-lg">
                                    <div className="portfolio-overlay justify-center items-center">
                                        <h1 className="text-center">Wildlife</h1>
                                    </div>
                                    <GatsbyImage image={tileImg4} alt="Action Photo"/>
                                </div>
                            </Link>
                    </div>
                      </Fade>
                </div>
        </section>
    );
};

export default Portfolio;