import React, {useState} from "react";
import ContactImage from "./ContactImage";
import FormImage from "./FormImage";
import LeftOverlayContent from "./LeftOverlayContent";
import RightOverlayContent from "./RightOverlayContent";

const ContactForm = () => {
    const [isAnimated,
        setIsAnimated] = useState(false);
    const overlayBg = "bg-gradient-to-r from-primary to-secondary";

    return (
            <div
                className="flex flex-col items-center justify-center h-3/4 w-full bg-gray-50">
                <div className="h-full w-full bg-white relative overflow-hidden">
                    <div
                        className={`signin bg-white absolute top-0 left-0 h-full w-1/2 flex justify-center items-center transform transition-all duration-700 ease-in-out z-20 ${isAnimated
                        ? "translate-x-full opacity-0"
                        : ""}`}>
                        <ContactImage/>
                    </div>

                    <div
                        className={`signup absolute top-0 left-0 h-full w-1/2 flex justify-center items-center transform transition-all duration-700 ease-in-out ${isAnimated
                        ? "translate-x-full opacity-100 z-50"
                        : "opacity-0 z-10"}`}>
                        <div className="h-full w-full flex justify-center items-center">
                            <FormImage/>
                        </div>
                    </div>

                    <div
                        className={`overlay-container absolute top-0 left-1/2 w-1/2 h-full overflow-hidden transform transition-transform duration-700 ease-in-out z-100 ${isAnimated
                        ? "-translate-x-full"
                        : ""}`}>
                        <div
                            className={`overlay ${overlayBg} relative -left-full h-full w-[200%] transform transition duration-700 ease-in-out ${isAnimated
                            ? "translate-x-1/2"
                            : "translate-x-0"}`}>
                            <div
                                className={`overlay-left w-1/2 h-full absolute justify-center items-center top-0 transform transition-transform duration-700 ease-in-out ${isAnimated
                                ? "translate-x-0"
                                : "-translate-x-[20%]"}`}>
                                <LeftOverlayContent isAnimated={isAnimated} setIsAnimated={setIsAnimated}/>
                            </div>
                            <div
                                className={`overlay-right w-1/2 h-full absolute flex justify-center items-center top-0 right-0 transform transition-transform duration-700 ease-in-out ${isAnimated
                                ? "translate-x-[20%]"
                                : "translate-x-0"}`}>
                                <RightOverlayContent isAnimated={isAnimated} setIsAnimated={setIsAnimated}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default ContactForm;
