import React, {useState} from "react";
import Fade from 'react-reveal/Fade';

const onSubmit = async(event, setSubmitText) => {
    event.preventDefault();
    setSubmitText("Submitting ...");
    const formElements = [...event.currentTarget.elements];
    const isValid = formElements.filter((elem) => elem.name === "bot-field")[0].value === "";

    const validFormElements = isValid
        ? formElements
        : [];

    if (validFormElements.length < 1) {
        // or some other cheeky error message
        setSubmitText("It looks like you filled out too many fields!");
    } else {
        const filledOutElements = validFormElements.filter((elem) => !!elem.value).map((element) => encodeURIComponent(element.name) + "=" + encodeURIComponent(element.value)).join("&");

        await fetch("/", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: filledOutElements
        }).then(() => {
            setSubmitText("Successfully submitted!");
        }).catch((_) => {
            setSubmitText("There was an error with your submission, please email me using this address: ana" +
                    "tole.tuzlak@gmail.com .");
        });
    }
};

const Form = () => {
    const [submitText,
        setSubmitText] = useState(null);
    return (
        <div
            id="contact-form"
            className="flex flex-col justify-center h-full bg-light p-10">
            <h1 className="text-6xl font-bold text-white mb-4 mx-auto">
                Get in Touch
            </h1>
            <div className="flex justify-center">
                <div className="w-full lg:w-9/12">
                    {submitText && <Fade duration={500} delay={500}>
                        <p className="text-white text-center p-4 rounded-lg mb-2">{submitText}</p>
                    </Fade>}
                    {submitText
                        ? null
                        : <form
                            name="contact"
                            method="post"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={e => onSubmit(e, setSubmitText)}>
                            <p
                                style={{
                                display: "none"
                            }}>
                                <label>
                                    Don’t fill this out if you expect to hear back!
                                    <input name="bot-field" value="" readOnly/>
                                </label>
                            </p>
                            <input type="hidden" name="form-name" value="contact"/>
                            <div className="mb-4">
                                <label htmlFor="name" className="block mb-2 text-white text-2xl">Your Name (required)</label>
                                <input
                                    required={true}
                                    className="form-control border-b-2 outline-none w-full h-16 p-3 text-xl"
                                    type="text"
                                    name="name"
                                    id="name"/>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="email" className="block mb-2 text-white text-2xl">Your Email (required)</label>
                                <input
                                    required={true}
                                    className="form-control border-b-2 outline-none w-full h-16 p-3 text-xl"
                                    type="email"
                                    name="email"
                                    id="email"/>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="message" className="block mb-2 text-white text-2xl">Your Message</label>
                                <textarea
                                    required={true}
                                    className="form-control border-b-2 outline-none w-full h-36 p-3 text-xl"
                                    name="message"
                                    type="text"
                                    id="message"/>
                            </div>
                            <button type="submit" className="cta-btn cta-btn--hero-w mt-3">
                                Submit
                            </button>
                        </form>
}
                </div>
            </div>
        </div>
    )
};

export default Form;