import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from "gatsby"
import Fade from 'react-reveal/Fade';
import {BgImage} from 'gbimage-bridge';
import BlockContent from "@sanity/block-content-to-react"
import {Link} from 'react-scroll';

const About = () => {

    const data = useStaticQuery(graphql `
    query {
        sanityHomePage {
          aboutSection {
            asset {
              gatsbyImageData
            }
            header
            _rawText
          }
        }
      }
  `);

    const aboutImg = data.sanityHomePage.aboutSection.asset.gatsbyImageData;
    const aboutHeader = data.sanityHomePage.aboutSection.header;
    const aboutText = data.sanityHomePage.aboutSection._rawText;

    const [isDesktop,
        setIsDesktop] = useState(false);
    const [isMobile,
        setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 1023) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    return (
        <section id="about">
            <div className="lg:container lg:pt-16">
                <div
                    className="grid grid-cols-1 lg:grid-cols-2 pb-5 items-center lg:mx-5 lg:py-16">
                    <Fade
                        left={isDesktop}
                        bottom={isMobile}
                        duration={1000}
                        delay={500}
                        distance="30px">
                        <div className="w-full h-500px lg:h-full">
                            <BgImage
                                aspectRatio={4 / 3}
                                image={aboutImg}
                                alt="Intro Image"
                                style
                                ={{
                                backgroundSize: "cover",
                                width: "100%",
                                height: "100%"
                            }}/>
                        </div>
                    </Fade>
                    <Fade right={isDesktop} bottom={isMobile} duration={1000} distance="30px">
                        <div className="p-10 lg:py-32">
                            <div className="about__heading">
                                <h1 className="uppercase">{aboutHeader}</h1>
                            </div>
                            <div className="about-wrapper__info-text">
                                <BlockContent blocks={aboutText}/>
                            </div>
                            <Link to="contact" title="Contact" smooth duration={1000} offset={-100}>
                                <button type="button" className="hero-cta cta-btn cta-btn--hero-w mt-10">Get in Touch</button>
                            </Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
    );
};

export default About;