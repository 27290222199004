import React, {useRef, useEffect, useCallback} from 'react';
import {useStaticQuery, graphql} from "gatsby";
import styled from 'styled-components';
import {MdClose} from 'react-icons/md';
import YoutubeEmbed from "./YoutubeEmbed";
import {motion} from 'framer-motion';

const Background = styled.div `
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999 !important;
`;

const ModalWrapper = styled.div `
  width: 75vw;
  height: auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: transparent;
  color: #000;
  position: relative;
  z-index: 9999 !important;
  border-radius: 10px;
  @media (max-width: 1024px) {
    width: 90vw;
  }
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  padding: 4px;
  z-index: 9999 !important;
  background: rgba(30,30,36,0.8);
  color: rgba(255, 255, 255, 0.8);
  transition: all .2s ease-in-out;
  &:hover{
      color: #fff;
  }
`;

export const Modal = ({showModal, setShowModal}) => {

    const data = useStaticQuery(graphql `
    query {
      sanityHomePage {
        demoReel
      }
    }     
  `);

    const demoReel = data.sanityHomePage.demoReel
    const embed = demoReel.substring(demoReel.indexOf('v=') + 2, demoReel.indexOf('v=') + 13)

    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [showModal]);

    const modalRef = useRef();

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowModal(false)
        }
    };

    const keyPress = useCallback(e => {
        if (e.key === 'Escape' && showModal) {
            setShowModal(false);
        }
    }, [setShowModal, showModal]);

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    const variants = {
        initial: {
            opacity: 0
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 1,
                when: 'beforeChildren'
            }
        }
    };

    return (
        <>
            {showModal
                ? (
                    <Background ref={modalRef} onClick={closeModal}>
                        <motion.div
                            variants={variants}
                            initial="initial"
                            animate="enter"
                            showModal={showModal}>
                            <ModalWrapper>
                                <YoutubeEmbed embedId={embed}/>
                            </ModalWrapper>
                            <CloseModalButton
                                    aria-label='Close modal'
                                    onClick={() => setShowModal(prev => !prev)}/>
                        </motion.div>
                    </Background>
                )
                : null}
        </>
    );
};