import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {BgImage} from "gbimage-bridge";
import Fade from 'react-reveal/Fade';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Mousewheel, FreeMode, Pagination} from 'swiper';

import "swiper/css/pagination"
import 'swiper/css';

SwiperCore.use([Mousewheel, FreeMode, Pagination]);

const Clients = () => {
    const data = useStaticQuery(graphql `
      query {
        sanityHomePage {
          clientSection {
            asset {
              gatsbyImageData(placeholder: NONE)
              id
            }
            alt
            _key
          }
        }
      }  
  `)

    const logoData = data.sanityHomePage.clientSection;

    return (
        <section id="clients" className="bg-gray-50">
            <h1 className="section-title">Clients</h1 >
            <Fade duration={1000} delay={500} distance="30px">
                <Swiper
                    slidesPerView={2}
                    slidesPerGroup={2}
                    pagination={{
                    "clickable": true
                }}
                    mousewheel={true}
                    grabCursor={true}
                    freeMode={true}
                    
                    breakpoints={{
                    "640": {
                        "slidesPerView": 3,
                        "slidesPerGroup": 3
                    },
                    "768": {
                        "slidesPerView": 4,
                        "slidesPerGroup": 2
                    },
                    "1024": {
                        "slidesPerView": 6,
                        "slidesPerGroup": 3
                    }
                }}>
                        {logoData.map(image => (
                            <SwiperSlide key={image._key}>
                                <div
                                    className="relative bg-white shadow-lg p-8 mb-16 mx-4 border-2 border-gray-50">
                                    <BgImage
                                        style
                                        ={{
                                        backgroundSize: "contain"
                                    }}
                                        image={image.asset.gatsbyImageData}
                                        className="p-logo"
                                        alt={image.alt
                                        ? image.alt
                                        : "Client Logo"}/>
                                </div>
                            </SwiperSlide>
                        ))}
                </Swiper>
            </Fade>
        </section>
    );
};

export default Clients;