import React from 'react';
import {useStaticQuery, graphql} from "gatsby";

import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import {Link} from 'react-scroll';
import MediaQuery from 'react-responsive'

const VideoContainer = styled.div `
  position: relative;
  overflow: hidden;
  background-color: white;


jumbotron {
  padding: 0;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;

const Landing = () => {

    const data = useStaticQuery(graphql `
    query {
        sanityHomePage {
          desktopBackgroundVideo
          mobileBackgroundVideo
          heroText
          backgroundVideoPoster {
            asset {
              url
            }
          }
        }
      }
  `);

//   const {height} = useWindowSize()

    const desktop = data.sanityHomePage.desktopBackgroundVideo;
    const mobile = data.sanityHomePage.mobileBackgroundVideo;
    const poster = data.sanityHomePage.backgroundVideoPoster.asset.url;
    const videoText = data.sanityHomePage.heroText;

    return (
        <section id="hero">
            <VideoContainer
                className="relative w-full">
                <video className="video-player" id="bgvideo" autoPlay loop muted playsInline poster={poster}>
                    <MediaQuery minWidth={769}>
                        <source src={desktop} type="video/mp4"/>
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                        <source src={mobile} type="video/mp4"/>
                    </MediaQuery>
                </video>
                <div className="flex flex-col items-center justify-end h-full p-8">
                    <Fade bottom duration={1000} delay={2300} distance="30px">
                        <h1 className="hero-title text-center">
                            {videoText}
                        </h1>
                    </Fade>
                    <Link
                        to="portfolio"
                        smooth
                        duration={1000}
                        offset={-100}
                        className="cursor-pointer">
                        <Fade bottom duration={1000} delay={2000} distance="30px">
                            <i className="fa fa-angle-down fa-5x" aria-hidden="true"/>
                        </Fade>
                    </Link>
                </div>
            </VideoContainer>
        </section>
    );
};

export default Landing;